import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Typography, usePersistSessionQuery, useRequest } from 'gtomy-lib';
import { PrvniSobotaDto } from '../models/dto/prvni-sobota.dto';

function getRemainingTime(nextPrvniSobota: Dayjs): number[] {
  const today = dayjs();
  const remainingDays = nextPrvniSobota.diff(today, 'day');
  const remainingHours = nextPrvniSobota.subtract(remainingDays, 'day').diff(today, 'hour');
  const remainingMinutes = nextPrvniSobota
    .subtract(remainingDays, 'day')
    .subtract(remainingHours, 'hour')
    .diff(today, 'minute');
  const remainingSeconds = nextPrvniSobota
    .subtract(remainingDays, 'day')
    .subtract(remainingHours, 'hour')
    .subtract(remainingMinutes, 'minute')
    .diff(today, 'second');

  return [
    Math.max(remainingDays, 0),
    Math.max(remainingHours, 0),
    Math.max(remainingMinutes, 0),
    Math.max(remainingSeconds, 0),
  ];
}

function CustomSpan({
  heldAt,
  type,
  getFormattedTimeType,
}: {
  heldAt?: string;
  type: number;
  getFormattedTimeType: (value: number, type: number) => string;
}) {
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    if (heldAt == null) {
      return;
    }
    const heldAtDate = dayjs(heldAt);
    setValue(getRemainingTime(heldAtDate)[type]);
    const interval = setInterval(() => {
      setValue(getRemainingTime(heldAtDate)[type]);
    }, 1000);
    return () => clearInterval(interval);
  }, [heldAt, type]);

  return (
    <>
      <span className="countdown font-mono text-5xl">
        {/*// @ts-ignore*/}
        <span style={{ '--value': value }}></span>
      </span>{' '}
      {getFormattedTimeType(value, type)}
    </>
  );
}

export function Countdown({ className }: { className?: string }) {
  const { get } = useRequest();
  const { data, QueryWrapper } = usePersistSessionQuery<PrvniSobotaDto | null>({
    queryKey: ['prvni-sobota', 'next'],
    queryFn: () => get<PrvniSobotaDto>('/prvni-sobota/next'),
    fallbackValue: null,
  });

  const getFormattedTimeType = (value: number, type: number): string => {
    if (value === 1) {
      switch (type) {
        case 0:
          return 'den';
        case 1:
          return 'hodina';
        case 2:
          return 'minuta';
        case 3:
          return 'sekunda';
      }
    }
    if (value >= 2 && value <= 4) {
      switch (type) {
        case 0:
          return 'dny';
        case 1:
          return 'hodiny';
        case 2:
          return 'minuty';
        case 3:
          return 'sekundy';
      }
    }
    switch (type) {
      case 0:
        return 'dní';
      case 1:
        return 'hodin';
      case 2:
        return 'minut';
      case 3:
        return 'sekund';
    }
    return '';
  };

  return (
    <QueryWrapper>
      <div className="flex flex-col items-center gap-4">
        <Typography size="6xl" weight="semibold" className="font-mono">
          {dayjs(data?.heldAt).format('D.M.YYYY HH:mm')}
        </Typography>
        <div className={twMerge('flex gap-5', className)}>
          {Array.from(Array(4).keys()).map((item) => (
            <div key={item}>
              <CustomSpan heldAt={data?.heldAt} type={item} getFormattedTimeType={getFormattedTimeType} />
            </div>
          ))}
        </div>
      </div>
    </QueryWrapper>
  );
}
