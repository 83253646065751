import { ArrowRightIcon, PhotoIcon } from '@heroicons/react/24/outline';
import {
  Button,
  ButtonIcon,
  CloudflareImage,
  Typography,
  useBreakpoint,
  useImageDialog,
  usePersistSessionQuery,
  useRequest,
} from 'gtomy-lib';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { PrvniSobotaHospodaDto } from '../models/dto/prvni-sobota-hospoda.dto';

function HospodaListItem({
  isOverBreakpoint,
  hospoda,
}: {
  isOverBreakpoint?: boolean;
  hospoda: PrvniSobotaHospodaDto;
}) {
  const { DialogElement, openDialog } = useImageDialog({
    title: hospoda.name,
    imageId: hospoda.imageId,
    effect: 'blur',
  });

  return (
    <>
      <DialogElement />
      <td
        className={twMerge(
          'cursor-pointer hover:opacity-80 align-top',
          isOverBreakpoint ? 'w-96 min-h-48' : 'w-52 min-h-24'
        )}
      >
        <CloudflareImage
          imageId={hospoda.imageId}
          srcType="miniature"
          width={isOverBreakpoint ? 352 : 176}
          onClick={openDialog}
        />
      </td>
    </>
  );
}

function HospodaListItemButton({ hospoda }: { hospoda: PrvniSobotaHospodaDto }) {
  const { DialogElement, openDialog } = useImageDialog({
    title: hospoda.name,
    imageId: hospoda.imageId,
    effect: 'blur',
  });

  return (
    <>
      <DialogElement />
      <ButtonIcon color="primary" onClick={openDialog} icon={PhotoIcon} />
    </>
  );
}

type SplitHospody = { city: string; hospody: PrvniSobotaHospodaDto[] }[];

export function HospodaList() {
  const { get } = useRequest();
  const { data, QueryWrapper } = usePersistSessionQuery<PrvniSobotaHospodaDto[]>({
    queryKey: ['prvni-sobota', 'hospody', 'ignore-duplicity'],
    queryFn: () => get<PrvniSobotaHospodaDto[]>('/prvni-sobota/hospody-ignore-duplicity'),
    fallbackValue: [],
  });
  const { isOverBreakpoint } = useBreakpoint('lg');
  const { isOverBreakpoint: isOverSmallBreakpoint } = useBreakpoint('sm');

  const splitHospody: SplitHospody = useMemo(() => {
    const ret: SplitHospody = [];
    for (const hospoda of data) {
      const index = ret.findIndex((sp) => sp.city === hospoda.city);
      if (index === -1) {
        ret.push({
          city: hospoda.city,
          hospody: [hospoda],
        });
      } else {
        ret[index].hospody = [...ret[index].hospody, hospoda];
      }
    }
    for (const retElement of ret) {
      retElement.hospody.sort((a, b) => a.name.localeCompare(b.name));
    }
    return ret;
  }, [data]);

  return (
    <>
      <Typography as="h1" size="6xl" weight="bold" className="pb-2 text-center">
        Seznam hospod
      </Typography>
      <QueryWrapper>
        <>
          {splitHospody.map((splitHospoda) => (
            <div key={splitHospoda.city} className="flex flex-col gap-4">
              <div className="divider"></div>
              <Typography size="3xl" weight="semibold" className="text-center">
                {splitHospoda.city}
              </Typography>
              <table className="table">
                <thead>
                  <tr>
                    {isOverSmallBreakpoint && <td>Fotka</td>}
                    <td>Jméno a popis</td>
                    {isOverBreakpoint && <td>Město</td>}
                    {isOverSmallBreakpoint ? <td>Odkaz na mapy</td> : <td>Mapy a Foto</td>}
                  </tr>
                </thead>
                <tbody>
                  {splitHospoda.hospody.map((hospoda) => (
                    <tr key={hospoda.hospodaId}>
                      {isOverSmallBreakpoint && (
                        <HospodaListItem hospoda={hospoda} isOverBreakpoint={isOverBreakpoint} />
                      )}
                      <td className="flex flex-1 flex-col gap-1">
                        <Typography size="xl" weight="semibold">
                          {hospoda.name}
                        </Typography>
                        <Typography>{hospoda.description}</Typography>
                      </td>
                      {isOverBreakpoint && <td className="w-32 align-top">{hospoda.city}</td>}
                      <td className="w-32 text-center align-top">
                        {isOverBreakpoint ? (
                          <Button
                            as="a"
                            rel="noreferrer"
                            href={hospoda.mapsUrl}
                            target="_blank"
                            size="sm"
                            endIcon={ArrowRightIcon}
                          >
                            Mapa
                          </Button>
                        ) : isOverSmallBreakpoint ? (
                          <ButtonIcon
                            as="a"
                            rel="noreferrer"
                            href={hospoda.mapsUrl}
                            target="_blank"
                            icon={ArrowRightIcon}
                          />
                        ) : (
                          <div className="flex flex-col items-center gap-4">
                            <HospodaListItemButton hospoda={hospoda} />
                            <ButtonIcon
                              as="a"
                              rel="noreferrer"
                              href={hospoda.mapsUrl}
                              target="_blank"
                              icon={ArrowRightIcon}
                            />
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </>
      </QueryWrapper>
    </>
  );
}
