import { CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';

export function HlasovaniIcon({ jde }: { jde: 'yes' | 'no' | 'unsure' }): JSX.Element | null {
  switch (jde) {
    case 'yes':
      return <CheckCircleIcon className="size-7 text-success" />;
    case 'no':
      return <XCircleIcon className="size-7 text-error" />;
    case 'unsure':
      return <QuestionMarkCircleIcon className="size-7 text-warning" />;
    default:
      console.warn(`[getIcon] Wrong passed ${JSON.stringify(jde)}. Allowed values: 'Ano', 'Ne', 'Možná'`);
      return null;
  }
}
