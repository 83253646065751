import {
  combineQueryWrapperProps,
  getUsersRoleId,
  PERM_ROLES,
  QueryWrapper,
  Typography,
  useAuth,
  usePersistSessionQuery,
  useRequest,
} from 'gtomy-lib';
import { useMemo } from 'react';
import { PrvniSobotaContentDto } from '../models/dto/prvni-sobota-content.dto';
import { ContentViewItem } from '../components/ContentViewItem';
import { PrvniSobotaDto } from '../models/dto/prvni-sobota.dto';
import dayjs from 'dayjs';

const PUBLIC_KEY = ['prvni-sobota', 'content', 'public'];
const PUBLIC_URL = '/prvni-sobota/content/public';
const PRIVATE_KEY = ['prvni-sobota', 'content'];
const PRIVATE_URL = '/prvni-sobota/content';

type SplitContent = { sobota: PrvniSobotaDto | null; contents: PrvniSobotaContentDto[] };

function useContent() {
  const { user } = useAuth();
  const canViewPrivateContent = useMemo(() => user != null && getUsersRoleId(user) >= PERM_ROLES.vip, [user]);
  const { get } = useRequest();
  const { data: contents, wrapperProps: wrapperProps1 } = usePersistSessionQuery<PrvniSobotaContentDto[]>({
    queryKey: canViewPrivateContent ? PRIVATE_KEY : PUBLIC_KEY,
    queryFn: () => get<PrvniSobotaContentDto[]>(canViewPrivateContent ? PRIVATE_URL : PUBLIC_URL),
    fallbackValue: [],
  });
  const { data: sobotas, wrapperProps: wrapperProps2 } = usePersistSessionQuery<PrvniSobotaDto[]>({
    queryKey: ['prvni-sobota', 'all'],
    queryFn: () => get<PrvniSobotaDto[]>('/prvni-sobota'),
    fallbackValue: [],
  });

  const splitContents: SplitContent[] = useMemo(() => {
    const ret: SplitContent[] = [];
    const unassigned: PrvniSobotaContentDto[] = [];
    for (const content of contents) {
      if (content.sobotaId == null) {
        unassigned.push(content);
        continue;
      }
      const index = ret.findIndex((c) => c.sobota?.sobotaId === content.sobotaId);
      if (index === -1) {
        const sobota = sobotas.find((sobota) => sobota.sobotaId === content.sobotaId);
        if (sobota == null) {
          unassigned.push(content);
          continue;
        }
        ret.push({
          sobota: sobota,
          contents: [content],
        });
      } else {
        ret[index].contents = [...ret[index].contents, content];
      }
    }
    ret.sort((a, b) => dayjs(b.sobota?.heldAt).unix() - dayjs(a.sobota?.heldAt).unix());
    ret.push({
      sobota: null,
      contents: unassigned,
    });
    return ret;
  }, [contents, sobotas]);

  return {
    splitContents,
    wrapperProps: combineQueryWrapperProps(wrapperProps1, wrapperProps2),
  };
}

export function Content() {
  const { splitContents, wrapperProps } = useContent();

  return (
    <>
      <div className="flex flex-col gap-8 text-center">
        <Typography as="h1" size="6xl" weight="bold">
          Fotky a videa
        </Typography>
        <div className="flex flex-col">
          <Typography size="xl" weight="semibold">
            Některé fotky/videa jsou zhlédnutelné/á pouze po přihlášení!
          </Typography>
          <Typography size="xl">
            Pro přidání dalších fotek a videí mi je stačí poslat buď na Discord nebo Messenger
          </Typography>
        </div>
      </div>
      <QueryWrapper {...wrapperProps}>
        <>
          {splitContents.map((splitContent) => (
            <div key={splitContent.sobota?.sobotaId ?? 'no-sobota'} className="flex flex-col gap-8">
              <div className="divider"></div>
              <Typography size="3xl" weight="semibold" className="text-center">
                {splitContent.sobota == null && 'Nepřiřazené'}
                {splitContent.sobota != null &&
                  splitContent.sobota.winner == null &&
                  dayjs(splitContent.sobota.heldAt).format('D.M.YYYY')}
                {splitContent.sobota != null &&
                  splitContent.sobota.winner != null &&
                  `${splitContent.sobota.winner.name} (${dayjs(splitContent.sobota.heldAt).format('D.M.YYYY')})`}
              </Typography>
              <div className="flex flex-wrap">
                {splitContent.contents.map((content) => (
                  <ContentViewItem key={content.contentId} content={content} sobota={splitContent.sobota} />
                ))}
              </div>
            </div>
          ))}
        </>
      </QueryWrapper>
    </>
  );
}
