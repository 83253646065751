import { PlayIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { Button, CloudflareImage, Typography, useImageDialog } from 'gtomy-lib';
import { PrvniSobotaContentDto } from '../models/dto/prvni-sobota-content.dto';
import { PrvniSobotaDto } from '../models/dto/prvni-sobota.dto';

export interface ContentViewItemProps {
  content: PrvniSobotaContentDto;
  sobota: PrvniSobotaDto | null;
}

export function ContentViewItem({ content, sobota }: ContentViewItemProps) {
  const location = sobota?.winner != null && `(${dayjs(sobota.heldAt).format('D.M.YYYY')} v ${sobota.winner.name})`;
  const { DialogElement, openDialog } = useImageDialog({
    title: content.title,
    subtitle: `${content.description ?? ''} ${location}`,
    imageId: content.imageId,
    videoId: content.videoId,
    effect: 'blur',
  });

  return (
    <>
      <DialogElement />
      <div className="flex h-96 w-1/2 cursor-pointer flex-col items-center gap-2 px-2 py-4 hover:opacity-80 lg:w-1/4">
        {content.imageId && (
          <CloudflareImage imageId={content.imageId} alt={content.title} srcType="miniature" onClick={openDialog} />
        )}
        {content.videoId && (
          <div className="flex items-center justify-center">
            <Button onClick={openDialog} size="lg" startIcon={PlayIcon}>
              Zobrazit video
            </Button>
          </div>
        )}
        <Typography size="lg">{content.title}</Typography>
      </div>
    </>
  );
}
