import { PrvniSobotaDto } from '../models/dto/prvni-sobota.dto';
import { Button, Typography } from 'gtomy-lib';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

export interface VotingDataProps {
  sobota: PrvniSobotaDto;
}

export function VotingData({ sobota }: VotingDataProps) {
  if (sobota.isVotingOpen) {
    return (
      <div className="flex w-full flex-col items-center gap-4 pb-16 lg:w-2/3">
        <div className="flex flex-col ">
          <Typography size="4xl" weight="medium">
            Hlasování o místu konání:
          </Typography>
          <Typography size="xl" weight="normal">
            (bude ukončeno {dayjs(sobota.heldAt).subtract(7, 'day').hour(12).format('D.M.YYYY HH:mm')})
          </Typography>
        </div>
        <Typography size="xl">
          P.S.: Pokud máš nějakou super hospodu, kterou bys nám chtěl/a doporučit, tak nám to klidně napiš. Všechno je
          otázka demokracie (a dostupnosti dobře studených piv).
        </Typography>
        <Button size="lg" color="primary" as={Link} to="/hlasovani" endIcon={ArrowRightIcon}>
          Hlasovat zde
        </Button>
      </div>
    );
  }

  if (sobota.isVotingFinished) {
    if (sobota.winner?.isWinnerConclusive) {
      return (
        <div className="flex w-full flex-col items-center gap-4 pb-16 lg:w-2/3">
          <Typography size="4xl">Hlasování o místu konání bylo dokončeno</Typography>
          <Typography size="4xl">
            Výherní míslo: <Typography weight="bold">{sobota.winner?.name}</Typography>
          </Typography>
          <Button as={Link} to="/hlasovani">
            Více zde
          </Button>
        </div>
      );
    }
    return (
      <div className="flex w-full flex-col items-center gap-4 pb-16 lg:w-2/3">
        <Typography size="4xl">Hlasování o místu konání bylo dokončeno</Typography>
        <Typography size="4xl">Výherní míslo se zpracovává...</Typography>
      </div>
    );
  }
  return (
    <div className="flex w-full flex-col items-center gap-4 pb-16 lg:w-2/3">
      <Typography size="4xl">
        Hlasování o místu konání začne{' '}
        {dayjs(sobota.heldAt).subtract(1, 'month').add(1, 'week').hour(12).format('D.M.YYYY HH:mm')}
      </Typography>
    </div>
  );
}
