import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  GTomyProvider,
  LoginPage,
  NOT_FOUND_ROUTE,
  PRIVACY_POLICY_ROUTE,
  RegisterPage,
  withColumnPage,
  withLazyPage,
  withRequireAuth,
} from 'gtomy-lib';
import { HomePage } from './pages/HomePage';
import { Content } from './pages/Content';
import { SobotaMenu } from './components/SobotaMenu';
import { SobotaFooter } from './components/SobotaFooter';
import { HospodaList } from './pages/HospodaList';
import { Profile } from './pages/Profile';

const AdminModule = lazy(() => import('./modules/AdminModule'));
const HlasovaniModule = lazy(() => import('./modules/HlasovaniModule'));

function App() {
  return (
    <GTomyProvider MenuComponent={SobotaMenu} FooterComponent={SobotaFooter} displayCookies>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/content" element={withColumnPage(Content)} />
        <Route path="/hospody" element={withColumnPage(HospodaList)} />
        <Route path="/hlasovani/*" element={withLazyPage(HlasovaniModule)} />
        <Route path="/admin/*" element={withRequireAuth(withLazyPage(AdminModule), 'moderator')} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/profile" element={withRequireAuth(withColumnPage(Profile), 'user')} />
        {PRIVACY_POLICY_ROUTE}
        {NOT_FOUND_ROUTE}
      </Routes>
    </GTomyProvider>
  );
}

export default App;
