import { ColumnPage, Typography } from 'gtomy-lib';
import { Countdown } from '../components/Countdown';
import { Voting } from '../components/Voting';

export function HomePage() {
  return (
    <ColumnPage className="items-center gap-16 text-center">
      <div className="hero relative block" style={{ backgroundImage: 'url(/banner.jpg)' }}>
        <div className="hero-overlay absolute bg-opacity-60"></div>
        <div className="hero-content relative py-16 text-neutral-content">
          <div className="flex flex-col gap-y-2">
            <Typography as="h1" size="7xl" weight="bold">
              První sobota
            </Typography>
            <Typography as="h2" size="2xl" weight="semibold">
              kdy se hospoda stává naším druhým domovem.
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex w-full flex-col gap-2 lg:w-4/5">
        <Typography size="xl" weight="medium">
          První sobota v měsíci, to je náš tradiční ostravskej mejdan. Přijď se s náma posedět, popovídat a pokecat o
          všem možnym a nemožnym. Ať už jsi mladej/á, nebo starej/á, single, nebo zadaný/á, vítáme všechny.
        </Typography>
        <Typography size="xl" weight="medium">
          Nechceš sedět doma a koukat na televizi? Chceš se potkat s lidma, co mají rádi pokec? Tak neváhej a přijď na
          první sobotu v měsíci!
        </Typography>
        <Typography size="xl">(A neboj, nemusíš pít jen pivo. Můžeš si dát i něco jinýho.)</Typography>
      </div>
      <div className="divider"></div>
      <Countdown />
      <div className="divider"></div>
      <Voting />
    </ColumnPage>
  );
}
