import {
  combineQueryWrapperProps,
  ProfileForm,
  QueryWrapper,
  Typography,
  useBreakpoint,
  usePersistSessionQuery,
  useRequest,
} from 'gtomy-lib';
import { PrvniSobotaVoteDto } from '../models/dto/prvni-sobota-vote.dto';
import { HlasovaniIcon } from '../components/HlasovaniIcon';
import { PrvniSobotaHospodaDto } from '../models/dto/prvni-sobota-hospoda.dto';
import dayjs from 'dayjs';

export function Profile() {
  const { get } = useRequest();
  const { data, wrapperProps: wrapperProps1 } = usePersistSessionQuery<PrvniSobotaVoteDto[]>({
    queryKey: ['prvni-sobota', 'history'],
    queryFn: () => get<PrvniSobotaVoteDto[]>('/prvni-sobota/history'),
    fallbackValue: [],
  });
  const { data: hospody, wrapperProps: wrapperProps2 } = usePersistSessionQuery<PrvniSobotaHospodaDto[]>({
    queryKey: ['prvni-sobota', 'hospody', 'ignore-all'],
    queryFn: () => get<PrvniSobotaHospodaDto[]>('/prvni-sobota/hospody-ignore-all'),
    fallbackValue: [],
  });
  const { isOverBreakpoint } = useBreakpoint('md');

  return (
    <ProfileForm>
      <Typography size="4xl" weight="bold" className="pb-2 text-center lg:col-span-2">
        Historie hlasů
      </Typography>
      <QueryWrapper {...combineQueryWrapperProps(wrapperProps1, wrapperProps2)}>
        <table className="table lg:col-span-2">
          <thead>
            <tr>
              <td>Vytvořeno</td>
              <td>Účast</td>
              {isOverBreakpoint && <td>Lidi</td>}
              <td>Hospody</td>
              {isOverBreakpoint && <td>Podmínka</td>}
            </tr>
          </thead>
          <tbody>
            {data.toReversed().map((vote) => (
              <tr key={vote.voteId}>
                <td className="align-top">{dayjs(vote.createdAt).format('D.M.YYYY')}</td>
                <td className="w-8 align-top">
                  <HlasovaniIcon jde={vote.isGoing} />
                </td>
                {isOverBreakpoint && <td className="align-top">{vote.people}</td>}
                <td className="align-top">
                  <ul className="list-disc">
                    {(vote.hospodaIds ?? []).map((hId) => {
                      const hospoda = hospody?.find((h) => h.hospodaId === hId);
                      return (
                        <li key={hId} className="break-words">
                          {hospoda == null ? hId : hospoda.name}
                        </li>
                      );
                    })}
                  </ul>
                </td>
                {isOverBreakpoint && <td className="break-words align-top">{vote.reason ?? '-'}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      </QueryWrapper>
    </ProfileForm>
  );
}
