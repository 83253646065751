import { Footer, FooterItem, useTranslation, withPermission } from 'gtomy-lib';
import { Link } from 'react-router-dom';

export function SobotaFooter() {
  const { t } = useTranslation('common');

  return (
    <Footer
      title="První sobota"
      subtitle="Vytvořil GTomy, 2024"
      height="tall"
      showIcon
      links={[
        {
          header: 'Stránky',
          links: (
            <>
              <FooterItem as={Link} to="/">
                Domů
              </FooterItem>
              <FooterItem as={Link} to="/content">
                Fotky a videa
              </FooterItem>
              <FooterItem as={Link} to="/hospody">
                Seznam hospod
              </FooterItem>
              <FooterItem as={Link} to="/hlasovani">
                Hlasování
              </FooterItem>
            </>
          ),
        },
        {
          header: 'Užitečné odkazy',
          links: (
            <>
              {withPermission(
                <FooterItem as="a" target="_blank" rel="noreferrer" href="https://discord.gg/nj7zrpk79A">
                  Pozvánka na discord
                </FooterItem>,
                'vip'
              )}
              <FooterItem as={Link} to="/privacy-policy">
                {t('privacy.title', { ns: 'pages' })}
              </FooterItem>
              <FooterItem as="a" target="_blank" rel="noreferrer" href="https://galleryeet.net">
                GallerYeet
              </FooterItem>
            </>
          ),
        },
      ]}
    />
  );
}
