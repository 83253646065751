import { usePersistSessionQuery, useRequest } from 'gtomy-lib';
import { PrvniSobotaDto } from '../models/dto/prvni-sobota.dto';
import { VotingData } from './VotingData';

export function Voting() {
  const { get } = useRequest();
  const { data, QueryWrapper } = usePersistSessionQuery<PrvniSobotaDto | null>({
    queryKey: ['prvni-sobota', 'next'],
    queryFn: () => get<PrvniSobotaDto>('/prvni-sobota/next'),
    fallbackValue: null,
  });

  return (
    <QueryWrapper>
      <VotingData sobota={data!} />
    </QueryWrapper>
  );
}
