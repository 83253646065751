import { Menu, MenuItem, usePersistSessionQuery, useRequest, withPermission } from 'gtomy-lib';
import { PrvniSobotaDto } from '../models/dto/prvni-sobota.dto';
import { Link } from 'react-router-dom';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

export function SobotaMenu() {
  const { get } = useRequest();
  const { data } = usePersistSessionQuery<PrvniSobotaDto | null>({
    queryKey: ['prvni-sobota', 'next'],
    queryFn: () => get<PrvniSobotaDto>('/prvni-sobota/next'),
    fallbackValue: null,
  });

  return (
    <Menu showAuth showIcon dropdownActions={<Link to="/profile">Profil</Link>}>
      <MenuItem as={Link} to="/">
        Domů
      </MenuItem>
      <MenuItem as={Link} to="/content">
        Fotky a videa
      </MenuItem>
      <MenuItem as={Link} to="/hospody">
        Seznam hospod
      </MenuItem>
      {withPermission(
        <MenuItem as={Link} to="/admin">
          Admin
        </MenuItem>,
        'moderator'
      )}
      {data != null && data.isVotingOpen && (
        <MenuItem as={Link} to="/hlasovani" className="bg-accent text-accent-content hover:text-base-content">
          Hlasovat zde
          <ArrowRightIcon className="size-5" />
        </MenuItem>
      )}
      {data != null && data.isVotingFinished && (
        <MenuItem as={Link} to="/hlasovani">
          Výsledky hlasování
        </MenuItem>
      )}
    </Menu>
  );
}
